import { alertController, toastController, loadingController, modalController, isPlatform } from '@ionic/vue';
import ImageModal from '@/components/modals/ImageModal.vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed } from "vue";

import { SplashScreen } from '@capacitor/splash-screen';
import moment from 'moment';

export function utils() {
  const { t, locale } = useI18n();
  const ORDER_STATUSES = {
    pendingPayment: '待付款',
    paid: '已付款',
    processing: 'processing',
    hold: 'hold',
    fail: 'failed',
    complete: 'completed',
    cancel: 'cancelled',
  }
  const getOrderStatusColor = (status: string) => {
    if ([ORDER_STATUSES.fail, ORDER_STATUSES.cancel].includes(status)) return 'medium';
    if (status == ORDER_STATUSES.pendingPayment) return 'danger';
    if (status == ORDER_STATUSES.processing) return 'tertiary';
    if (status == ORDER_STATUSES.hold) return 'warning';
    if (status == ORDER_STATUSES.complete) return 'success';
    return 'light';
  }
  const focusInputField = (refEl: any) => {
    refEl.$el.setFocus();
  }

  const syncFilterBtnPosition = (categorySegmentBtns: any, categoryFilter: any) => {
    if (categorySegmentBtns) {
      for (let i = 0; i < categorySegmentBtns.length; i++) {
        const el: any = categorySegmentBtns[i];
        if (el.value == categoryFilter) {
          setTimeout(() => {
            el.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start'
            });
          }, 50);
          break;
        }
      }
    }
  }
  const openModal = async (component: any, componentProps: any, backdropDismiss = true, cssClass = '', sheetModal = false,
                            initialBreakpoint = 1, showHandle = false, onDidDismissCallback = null) => {
    const modal = await modalController.create({
      ...(sheetModal ? {
        breakpoints: [...new Set([0, initialBreakpoint, 1])],
        initialBreakpoint,
        handle: showHandle,
      } : {}),
      cssClass,
      backdropDismiss,
      component,
      componentProps,
    });
    if (onDidDismissCallback) modal.onDidDismiss().then(onDidDismissCallback);
    await modal.present();
    return modal;
  }
  const getProxyImgLink = (imgLink: any) => (imgLink && imgLink.startsWith("http") ? `https://cms.signals.hk/imgproxy.php?url=${encodeURIComponent(imgLink)}` : imgLink);
  const addResizeUrlParams = (photoLink: any, imageWidth = 300, returnProxyImgLink = true) => {
    if (photoLink && photoLink.includes("http")) {
      try {
        const url = new URL(photoLink);
        url.searchParams.append('resizeImage', 'true');
        url.searchParams.append('imageWidth', imageWidth.toString());
        return returnProxyImgLink ? getProxyImgLink(url.toString()) : url.toString();
      } catch (e) {
        console.error(e);
        return photoLink;
      }
    }
    return photoLink;
  }

  return {
    openModal,
    iPhoneNativeApp: () => (isPlatform('ios') && !isPlatform('mobileweb')),
    addResizeUrlParams, getProxyImgLink,
    openImageModal: async (imageLink: any, caption: any) => (await openModal(ImageModal, { imageLink, caption })),
    closeModal: async (data: any = {}) => (await modalController.dismiss(data)),

    infiniteScrollLoadData: (ev: any, numOfVisibleItems: any, items: any) => {
      if (numOfVisibleItems.value > items.length) {
        ev.target.complete();
      } else {
        setTimeout(() => {
          numOfVisibleItems.value += 20;
          ev.target.complete();
        }, 500);
      }
    },

    ORDER_STATUSES,
    getOrderStatusColor,
    focusInputField,

    syncFilterBtnPosition,
    sleep: (s: any) => {
      return new Promise((resolve) => {
        setTimeout(resolve, s * 1000);
      });
    },
    tStr: (chi, eng) => (locale.value == 'zh' ? chi : eng),
    getLocalizedStr: (dataObj: any, keyChi: any, keyEn: any) => {
      return dataObj ? (locale.value == 'zh' ? dataObj[keyChi] : (dataObj[keyEn] || dataObj[keyChi])) : "";
    },
    numberWithCommas: (x: any) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    addDaysToDate: (date: any, days: any) => {
      days = days == "" ? 1 : days; // default add 1 day
      return (moment(new Date(date))).add(days, 'days');
    },
    getRelativeDate: (date: any) => (moment(new Date(date)).fromNow()),
    formatDate: (date: any, dateFormat: any = 'YYYY/MM/DD HH:mm') => moment(new Date(date)).format(dateFormat),
    formatDateString: (d: any, dateFormat: any = 'YYYY/MM/DD') => (d ? moment(new Date(d)).format(dateFormat) : ""),
    dateDiffInDays: (a: any, b: any) => (moment(a).diff(moment(b), 'days')),

    reloadApp: () => {
      SplashScreen.show();
      window.location.reload();
    },
    presentPrompt: async (header: any = "", message: any = "", callback: any, cssClass = "") => {
      const alert = await alertController.create({
        header,
        message,
        cssClass,
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('confirm'),
            handler: callback,
          }
        ]
      });
      return alert.present();
    },
    presentAlert: async (msg: string, hideHeader = false) => {
      const obj: any = {
        header: t('errorHeader'),
        message: msg,
        buttons: ['OK']
      }
      if (hideHeader) delete obj.header;
      const alert = await alertController.create(obj);
      return await alert.present();
    },
    presentToast: async(msg: string, duration = 3000, position: any = 'bottom', buttons = []) => {
      const toast = await toastController.create({
        message: msg,
        duration,
        position,
        buttons,
      });
      toast.present();
    },
    presentVerifyCodeInputAlert: async (phone: any, callback: any) => {
      loadingController.dismiss();
      const alert = await alertController.create({
        backdropDismiss: false,
        header: t('RegisterPage.verifyingMobileNumber'),
        subHeader: `${t('RegisterPage.verifyMobileNumberInstruction')} (${phone}).`,
        inputs: [
          {
            name: 'verificationCode',
            type: 'text',
            placeholder: t('RegisterPage.verificationCode'),
            attributes: {
              inputmode: 'numeric',
            }
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('RegisterPage.verify'),
            handler: (value) => {
              if (value.verificationCode) {
                callback(value.verificationCode);
              }
              return false; // not closing the alert
            },
          },
        ],
      });
      await alert.present();
    }
  };
}