import { storefrontOutline, home, calendar, notifications, personOutline, basketOutline, newspaperOutline, settingsOutline, chatbubbleOutline,
        cardOutline, logoGoogle, logoApple, logoPaypal, logoAlipay, logoWechat, businessOutline, business, } from 'ionicons/icons';

export default {
  versionCode: '2.1.0',
  websiteUrl: 'https://bellykulu.com',
  
  primaryColor: '#F16C5D',
  logo: require('@/assets/logo.png'),
  logoWithText: require('@/assets/logo_text.png'),

  Firebase: {
    apiKey: "AIzaSyCaL2L9gDOo-Ii0AtnLkfkHIivQ2efFbxI",
    authDomain: "hkbakeries.firebaseapp.com",
    projectId: "hkbakeries",
    storageBucket: "hkbakeries.appspot.com",
    messagingSenderId: "99227228934",
    appId: "1:99227228934:web:21f25a1041561ab512dc87",
    measurementId: "G-VQR7XLYHV9"
  },

  OneSignal: {
    appId: '577b2a6f-8999-4fae-9844-bf69b7549e38',
  },

  HomePage: {
    banner: {
      slidingSpeed: 1000, // Duration of transition between slides (in ms)
      autoPlayDelay: 3000, // Delay between transitions (in ms)
      desktopSlidesPerView: 1,
    }
  },

  authPages: ["/login"],
  memberPages: ["/checkout", "/profile", '/notifications'],

  paymentMethods: [
    { key: "Credit Card", value: "card", icon: cardOutline },
    { key: "FPS 轉數快", value: "fps", icon: businessOutline }, // mainly for testing use
    { key: "Bank Transfer", value: "bank", icon: businessOutline }, // mainly for testing use
    //{ key: "Google Pay", value: "googlePay", icon: logoGoogle },
    //{ key: "Apple Pay", value: "applePay", icon: logoApple },
    /*{ key: "PayPal", value: "paypal", icon: logoPaypal },
    { key: "AlipayHK", value: "alipayhk", icon: logoAlipay },
    { key: "WeChat Pay HK", value: "wechatpayhk", icon: logoWechat },*/
  ],
}
