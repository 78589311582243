import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  // UPDATE
  async updateUserCartItem(cartItemId: any, quantity: number) {
    return await firebase.app().functions('asia-east2').httpsCallable('cart-updateUserCartItem')({ cartItemId, quantity });
  },
  async addNewCartItem(productId: any, cartItem: any, productQuestions: any = []) {
    return await firebase.app().functions('asia-east2').httpsCallable('cart-addNewCartItem')({ productId, cartItem, productQuestions });
  },
  async syncCartItems(newItems: any = [], updatedItems: any = []) {
    return await firebase.app().functions('asia-east2').httpsCallable('cart-syncCartItems')({ newItems, updatedItems });
  }
}