import { createStore, createLogger } from 'vuex';

import state from './state';
import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

export default createStore({
  state,
  getters,
  actions,
  mutations,
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})