// customized actions
import CartService from '@/services/CartService';
import MerchantService from '@/services/MerchantService';
import UserService from '@/services/UserService';

export const getUserData = ({ state, commit }, { firebaseUser, i18n, resetCartItems }) => {
  commit('setLoadingUserData', true);
  UserService.getUserData().then(res => {
    const { user } = res;
    if (user) {
      if (i18n && user.preferredLang) i18n.global.locale.value = user.preferredLang.toLowerCase(); // update language setting
    } else {
      // user record not exist in DB yet right after registration
      res.user = {
        id: firebaseUser.uid,
        email: firebaseUser.email,
        profilePic: "https://ionicframework.com/docs/demos/api/avatar/avatar.svg",
        preferredLang: i18n.global.locale.value,
        darkTheme: "N",
      };
    }
    // Sync with local cart items
    if (state.cartItems.length > 0) {
      const newItems = [], updatedItems = [];
      state.cartItems.forEach((localItem) => {
        if (localItem.isCustomized == 0) { // standard item
          const serverItem = res.cartItems.find((item) => item.productId == localItem.productId);
          if (serverItem) {
            serverItem.quantity = (+serverItem.quantity) + (+localItem.quantity);
            updatedItems.push(serverItem);
          } else {
            res.cartItems.push(localItem);
            newItems.push(localItem);
          }
        } else { // customized item
          res.cartItems.push(localItem);
          CartService.addNewCartItem(localItem.productId, localItem, localItem.productQuestions); // update DB cart item
        }
      });
      CartService.syncCartItems(newItems, updatedItems); // sync local cart items to server
    }
    commit('receiveAppUserData', res);

    if (resetCartItems) commit('receiveCartItems', []); // clear cart after submitting orders
  });
}

export const updateLikedMerchant = ({ state, getters, commit }, { merchantId, action }) => {
  if (action == 'add') MerchantService.addUserLikedMerchant(merchantId);
  else MerchantService.removeUserLikedMerchant(merchantId);
}

export const addProductToCart = ({ state, getters, commit }, { product, cartItem, productQuestions = [] }) => {
  const baseItemData = {
    productId: product.id,
    productImage: product.imageLink, // to be replaced by AppSheet virtual columns
    productName: product.title, // to be replaced by AppSheet virtual columns
  }
  if (productQuestions.length > 0) { // customizable products: always create new cart items
    if (cartItem.id == null) cartItem.id = `ci${Math.random().toString(36).slice(-8)}`; // random ID as the key
    commit('upsertCartItem', {
      cartItemObj: {
        ...baseItemData,
        ...cartItem,
      },
      checkField: 'id',
    });
    if (state.loggedIn) CartService.addNewCartItem(product.id, cartItem, productQuestions); // update DB cart item
  } else { // non-customizable products
    const existingCartItem = getters.getCartItem(product.id);
    if (existingCartItem) { // update existing cart item
      const newQty = +existingCartItem.quantity + cartItem.quantity;
      commit('upsertCartItem', {
        cartItemObj: {
          ...existingCartItem,
          ...cartItem,
          quantity: newQty,
        }
      });
      if (state.loggedIn) {
        CartService.updateUserCartItem(existingCartItem.id, newQty); // update DB cart item
      }
    } else { // add new cart item
      if (cartItem.id == null) cartItem.id = `ci${Math.random().toString(36).slice(-8)}`; // random ID as the key
      commit('upsertCartItem', {
        cartItemObj: {
          ...baseItemData,
          ...cartItem,
        }
      });
      if (state.loggedIn) CartService.addNewCartItem(product.id, cartItem); // update DB cart item
    }
  }
}

// Template Actions
export const updateUserLastCheckNotificationTime = ({ state, commit }) => {
  state.user.lastCheckNotificationsAt = new Date();
  UserService.updateLastCheckNotificationTime();
}
export const createNewUser = ({ commit }, newUser) => {
  UserService.createNewUser(newUser).then(newUserRow => {
    commit('receiveUser', newUserRow); // this updates projects related to user as well
  });
}
export const resetUserData = ({ commit }) => {
  commit('receiveUser', {}); // empty the store user object
  commit('receiveCartItems', []);
}