
// Vue reactivity
import { computed, defineComponent } from 'vue';

// icons
import { arrowBackOutline } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
        IonButton, IonButtons, modalController, } from '@ionic/vue';

// API services
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'NotificationDetailModal',
  props: ["id"],
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonButton, IonButtons, },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingNotifications);
    const notification = computed(() => store.getters.getNotificationById(props.id));

    // 2. methods or filters
    const { formatDate } = utils();
    const closeModal = async () => { await modalController.dismiss() };
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBackOutline,

      // variables
      loading, notification,

      // methods
      t, formatDate, closeModal,
    }
  },
})
