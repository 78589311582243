const handleUserData = (state, user) => {
  const userId = user.id;
  state.user = user;
  state.loggedIn = (userId ? true : false);
  if (userId) {
    const { purchasedProductIds, likedProductIds, likedMerchantIds } = user;
    state.allProducts.forEach(product => {
      if (purchasedProductIds) product.purchasedByUser = purchasedProductIds.includes(product.id);
      if (likedProductIds) product.likedByUser = likedProductIds.includes(product.id);
    });
    state.allMerchants.forEach(m => {
      if (likedMerchantIds) m.likedByUser = likedMerchantIds.includes(m.id);
    });
  }
  state.loadingUser = false;
}

const injectProductData = (state) => {
  const { allProducts } = state;
  if (allProducts && allProducts.length > 0) {
    state.cartItems.forEach(cartItem => {
      const relatedProduct = allProducts.find(p => p.id == cartItem.productId);
      if (relatedProduct) {
        cartItem.productName = relatedProduct.title;
        cartItem.productImage = relatedProduct.imageLink;
        cartItem.unitPrice = Number(relatedProduct.sellingPrice) + Number(cartItem.answerTotalPrice);
      }
    })
    state.userOrders.forEach(order => {
      for (const orderItem of order.items) {
        const relatedProduct = allProducts.find(p => p.id == orderItem.productId);
        if (relatedProduct) {
          orderItem.productImage = relatedProduct.imageLink;
        }
      }
    })
  }
}

export default {
  receiveAppPublicData(state, res) {
    const { productCategories, products, settings, posts, postCategories,
            merchants, pickupPoints, deliveryOptions, districts, banners, homeSections,
            productQuestions, productQuestionOptions, productPrepDays, } = res;
    const { user } = state, userId = user.id;
    if (settings) {
      state.settings = settings;
      state.maxDescriptionLen = Number(settings.readMoreThreshold) || 50;
    }
    if (productPrepDays) state.productPrepDays = productPrepDays;
    if (productCategories) state.allProductCategories = productCategories;
    if (products) {
      products.forEach(p => {
        if (userId) {
          p.purchasedByUser = user.purchasedProductIds.includes(p.id);
          p.likedByUser = user.likedProductIds.includes(p.id);
        }
        p.photoLinks = p.photoLinks ? p.photoLinks.split(" , ") : [];
        p.inStock = p.inStock == 'Y';
      });
      state.allProducts = products;
      injectProductData(state);
    }
    if (productQuestions) {
      productQuestions.forEach(q => {
        q.isRequired = q.isRequired == 'Y';
        q.cbShowTextbox = q.cbShowTextbox == 'Y';
        q.options = productQuestionOptions.filter(opt => opt.questionId == q.id);
      });
      state.productQuestions = productQuestions;
    }
    if (posts) {
      posts.forEach(p => {
        p.photoLinks = p.photoLinks ? p.photoLinks.split(" , ") : [];
      });
      state.allPosts = posts;
    }
    if (postCategories) state.allPostCategories = postCategories;
    if (homeSections) state.homeSections = homeSections || [];
    if (merchants) {
      merchants.forEach(m => {
        if (userId) m.likedByUser = user.likedMerchantIds.includes(m.id);
      });
      state.allMerchants = merchants;
    }
    if (deliveryOptions) state.deliveryOptions = deliveryOptions;
    if (districts) state.allDistricts = districts;
    if (pickupPoints) state.pickupPoints = pickupPoints;
    if (banners) state.homeBanners = banners.filter(b => b.image && b.page == 'home').sort((a, b) => a.order - b.order);

    state.loadingAppPublicData = false;
  },

  // USER
  setLoadingUserData(state, isLoading) {
    state.loadingUser = isLoading;
  },
  receiveAppUserData(state, res) {
    const { user, userOrders, cartItems, } = res;
    if (user) handleUserData(state, user);
    if (userOrders) state.userOrders = userOrders;
    if (cartItems) {
      state.cartItems = cartItems;
      injectProductData(state);
    }
  },
  receiveUser(state, user) {
    handleUserData(state, user);
  },
  updateUser(state, updatedObj) {
    for (const key in updatedObj) state.user[key] = updatedObj[key];
  },

  // PRODUCT
  addUserBrowsedProduct(state, productId) {
    if (!state.user.browsedProductIds?.includes(productId)) {
      state.user.browsedProductIds.unshift(productId);
    }
  },

  // CART ITEMS
  receiveCartItems(state, cartItems) {
    state.cartItems = cartItems;
  },
  upsertCartItem(state, { cartItemObj, checkField = 'productId' }) {
    const idx = state.cartItems.findIndex(item => item[checkField] == cartItemObj[checkField]);
    if (idx !== -1) {
      state.cartItems.splice(idx, 1, cartItemObj);
    } else {
      state.cartItems.push(cartItemObj);
    }
  },

  // NOTIFICATION
  receiveNotifications(state, data) {
    state.allNotifications = data;
    state.loadingNotifications = false;
  },

  // POSTS
  receiveAllPosts(state, allPosts) {
    allPosts.forEach(p => {
      p.photoLinks = p.photoLinks ? p.photoLinks.split(" , ") : [];
    });
    state.allPosts = allPosts;
  },
  updatePost(state, updatedPost) {
    state.allPosts = [
      ...state.allPosts.map(post => post.id !== updatedPost.id ? post : {...post, ...updatedPost})
    ];
  },
  deletePost(state, targetPostId) {
    const idx = state.allPosts.findIndex(post => post.id == targetPostId);
    state.allPosts.splice(idx, 1);
  },
}