
import config from '@/config';

export default {
  props: [
    "headerLogo",
    "withText"
  ],
  setup() {
    const { logo, logoWithText } = config;
    return { logo, logoWithText }
  }
}
