import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7af2f6ad"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.headerLogo)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "logo-header-img",
        src: $setup.logoWithText
      }, null, 8, _hoisted_1))
    : ($props.withText)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "logo-text-img",
          src: $setup.logoWithText
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("img", {
          key: 2,
          class: "logo-img",
          src: $setup.logo
        }, null, 8, _hoisted_3))
}