import { RouteRecordRaw } from 'vue-router';
import Tabs from '@/pages/Tabs.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/pages/auth/LoginPage.vue')
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/home'
      },
      {
        path: 'home',
        component: () => import('@/pages/HomePage.vue')
      },
      {
        path: 'products',
        alias: ['product-list/:triggerSearch?/:preSelectCategoryId?', 'product-list-search'],
        name: 'ProductListPage',
        component: () => import('@/pages/ProductListPage.vue'),
      },
      {
        path: 'products/:id',
        name: 'ProductDetailPage',
        component: () => import("@/pages/ProductDetailPage.vue"),
        props: true,
      },
      {
        path: 'liked-products',
        component: () => import('@/pages/UserLikedItemListPage.vue'),
      },
      {
        path: 'cart',
        component: () => import('@/pages/order/CartPage.vue')
      },
      {
        path: 'checkout',
        name: 'CheckoutPage',
        component: () => import('@/pages/order/CheckoutPage.vue'),
        props: true,
      },
      {
        path: 'thank-you',
        name: 'ThankYouPage',
        component: () => import('@/pages/order/ThankYouPage.vue'),
        props: true,
      },
      {
        path: 'posts',
        component: () => import('@/pages/PostListPage.vue'),
      },
      {
        path: "posts/:id",
        name: "PostDetailPage",
        component: () => import("@/pages/PostDetailPage.vue"),
        props: true,
      },
      {
        path: 'profile',
        component: () => import('@/pages/UserProfilePage.vue')
      },
      {
        path: 'orders',
        name: 'OrderListPage',
        component: () => import('@/pages/order/OrderListPage.vue'),
      },
      {
        path: "orders/:id",
        name: "OrderDetailPage",
        component: () => import("@/pages/order/OrderDetailPage.vue"),
        props: true,
      },
      {
        path: 'terms-and-conditions',
        component: () => import('@/pages/TermsAndConditionsPage.vue')
      },
      {
        path: 'feedback/:productId?',
        name: 'FeedbackPage',
        component: () => import('@/pages/FeedbackPage.vue')
      },
      {
        path: 'merchants',
        name: "MerchantListPage",
        component: () => import('@/pages/MerchantListPage.vue'),
      },
      {
        path: "merchants/:id",
        name: "MerchantDetailPage",
        component: () => import("@/pages/MerchantDetailPage.vue"),
        props: true,
      },
      {
        path: 'notifications',
        name: 'NotificationListPage',
        component: () => import('@/pages/notification/NotificationListPage.vue'),
      },
      {
        path: 'notifications/:id',
        name: 'NotificationDetailPage',
        component: () => import("@/pages/notification/NotificationDetailPage.vue"),
        props: true,
      },
      {
        path: 'liked',
        component: () => import('@/pages/UserLikedListPage.vue'),
      },
      {
        path: 'sections/:id',
        name: 'HomeSectionPage',
        component: () => import('@/pages/HomeSectionPage.vue'),
      },

      /**
       * Stripe
       */
      {
        path: 'payment-methods',
        name: 'PaymentMethodListPage',
        component: () => import("@/pages/order/PaymentMethodListPage.vue"),
      },
    ]
  }
]