
// vue
import { computed, ref, watch } from 'vue';

// icons
import { storefrontOutline, home, personOutline, basketOutline, newspaperOutline, } from 'ionicons/icons';

// components
import { IonPage, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonBadge, IonRouterOutlet, } from '@ionic/vue';

// composables
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import config from '@/config';

export default {
  name: 'Tabs',
  components: { IonPage, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonBadge,
                IonRouterOutlet, },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();

    const hideTabs = ref(false);
    const numOfCartItems = computed(() => store.getters.numOfCartItems);

    const checkHideTabs = (routePath: any) => {
      hideTabs.value = (
        //routePath.split("/").length > 2 || config.authPages.includes(routePath)
        config.authPages.includes(routePath)
      );
    }
    watch(() => route.path, (newPath) => {
      checkHideTabs(newPath);
    })
    checkHideTabs(route.path); // triggered on first enter APP

    return {
      t,

      hideTabs, numOfCartItems,

      tabs: [
        { icon: home, slug: 'home', label: 'home' },
        { icon: storefrontOutline, slug: 'products', label: 'shop' },
        { icon: basketOutline, slug: 'cart', label: 'cart' },
        { icon: newspaperOutline, slug: 'posts', label: 'latestPosts' },
        //{ icon: chatbubbleOutline, slug: 'inquiry', label: 'inquiry' },
        { icon: personOutline, slug: 'profile', label: 'profile' },
      ],
    }
  }
}
