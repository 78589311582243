
import { computed, ref } from 'vue';

// icons
import { chevronBackOutline, searchOutline, notificationsOutline, arrowBack, close, } from 'ionicons/icons';

// components
import { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton, IonLabel,
        IonBackButton, IonIcon, IonTitle, modalController, } from '@ionic/vue';
import LogoImg from "@/components/LogoImg.vue";
import NotificationListModal from '@/components/notification/NotificationListModal.vue';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  props: [
    "titleOnly",
    "title",
    "parentPath",
    "hideIcons",
    "showSearchIcon",
    "showCloseModal",
  ],
  components: { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton, IonLabel,
                IonBackButton, IonIcon, IonTitle, LogoImg },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const userLoggedIn = computed(() => store.state.loggedIn);

    const openNotificationListModal = async () => {
      const modal = await modalController.create({
        component: NotificationListModal,
      });
      return modal.present();
    };

    return {
      // icons
      searchOutline, notificationsOutline, arrowBack, close,

      // variables
      userLoggedIn,

      // methods
      t, openNotificationListModal,
      closeModal: async () => (await modalController.dismiss()),
    }
  }
}
