export default {
  loadingAppPublicData: true,
  loadingUser: false,
  user: {},
  loggedIn: false,

  allMerchants: [],
  allDistricts: [],
  deliveryOptions: [],
  pickupPoints: [],

  // Order
  userOrders: [],

  // Cart Item
  cartItems: [],

  // Notification
  allNotifications: [],
  loadingNotifications: true,
  
  // Home
  homeBanners: [],
  homeSections: [],

  // Product
  allProductCategories: [],
  allProducts: [],
  productQuestions: [],
  productPrepDays: [],
  maxDescriptionLen: 50, // threshold for showing read more button in Product Detail page
  
  // Post
  allPostCategories: [],
  allPosts: [],
}