export default {
  zh: {
    done: '完成',
    loginOrRegister: '登入 / 註冊',
    next: '下一步',
    loginPhone: '您的手機號碼',
    finish: '完成',
    completeRegistration: '完成您的註冊',
    nickname: '您的暱稱',
    category: '產品類別',
    priceRange: "價錢範圍",
    loadingMore: '載入中...',
    brand: '品牌',
    moq: '最少訂購數量',
    minPrepDays: '準備需時',
    orderQty: '訂購數量',
    '待付款': '待付款',
    '已付款': '已付款',
    '已完成': '已完成',

    deleteAccount: '刪除帳戶',
    confirmDeleteAccountMsg: '注意：確定刪除此帳戶及所有相關資料?',
    accountDeleted: '帳戶已刪除',

    myPaymentMethods: '我的付款方式',
    saveCreditCard: '儲存你的信用卡',
    addNewCreditCard: '添加信用卡/扣帳卡',
    creditCard: '信用卡 (Visa/MasterCard)',

    serverError: '不好意思，伺服器發生錯誤，請重試。',
    serverBusy: '不好意思，伺服器忙碌中，請重試。',
    
    shop: '商城',
    cart: '購物車',
    checkout: '結帳',
    forum: '討論區',
    profile: '帳戶',
    notifications: '通知',
    settings: '設定',
    inquiry: '查詢',

    sale: '特價',
    soldOut: '售罊',

    yes: '是',
    no: '否',
    back: '返回',
    confirm: '確定',
    cancel: '取消',
    all: '全部',

    remark: '備註',
    submit: '提交',

    buyNow: '立即購買',
    addToCart: '加入購物車',
    addToLikedItems: '收藏',
    removeFromLikedItems: '從我的清單移除',
    enquiry: '聯繫客服',
    product: '產品',
    
    successUpdateLikedItems: '成功更新喜愛清單',
    successAddedToCart: '成功加到購物車',

    errorHeader: '錯誤！',

    deliveryMethod: '送貨方式：',
    deliveryDistrict: '送貨地區：',
    deliveryAddress: '送貨地址：',
    customerName: '客戶姓名：',
    contactPhone: '聯絡電話：',
    customerEmail: '電郵地址：',

    CartPage: {
      lineTotal: '總計：',
      orderTotal: '商品總額：',
      checkout: '前往付款',
      shopNow: '即去購物',
      emptyCart: '購物車內沒有任何商品',
      removeCartItem: '刪除購物車商品',
      confirmRemoveCartItem: '確定移除此購物車商品？',
    },

    OrderPage: {
      order: '訂單',
      orderStatus: '訂單狀態',
      orderItems: '訂單產品',
      lineTotal: '總計：',
      orderTotal: '商品總額：',
      deliveryInfo: '送貨資料',
      orderNumber: '訂單號碼：',
      total: '合共：',
    },

    PostPage: {
      newPost: '新帖子',
      lastUpdated: '最後更新：',
      numOfComments: '則留言',
      newComment: '新增留言',
      commentBtnText: '留言',
      editPost: '編輯帖子',
      postTitle: '帖子主題',
      postContent: '帖子內容',
      postImage: '圖片',
      updatePost: '更新帖子',
      publishPost: '發佈帖子',
      successPublishPost: '已成功發佈帖子。',
      successUpdatePost: '已成功更新帖子。',
      confirmDelete: '確認刪除',
      confirmDeletePost: '確認刪除此帖子？',
      deletePost: '刪除帖子',
      postDeleted: '已刪除帖子。',
    },

    ThankYouPage: {
      thankYouForYourOrder: '感謝您的訂單！',
      description: '稍候您將會收到訂單確認通知。',
      backToHomePage: '返回主頁',
    },

    // add extra zh translations below
    registeringAccount: '設定帳戶中...',
    loginInformation: '登入帳號資料',
    successUpdatePersonalInfo: '已成功更新個人資料。',
    clashEmailAddress: '此電子郵件地址已被使用。',
    wrongPassword: '登入資料錯誤！請重新輸入。',
    editMobileNumber: '更改手機號碼',
    
    home: '首頁',
    merchant: '商家',
    allMerchants: '所有商家',
    latestPosts: '最新資訊',
    readMore: '閱讀更多',
    search: '搜尋',

    shareProduct: '分享給朋友',
    earliestDeliveryDate: '最早可取貨日期',
    pickupPoints: '自取點',
    deliveryDistricts: '可送貨地區',
    '港島': '港島',
    '九龍': '九龍',
    '新界': '新界',
    '離島': '離島',
    shareWithFriends: '分享給朋友',
    productIntro: '產品介紹',
    orderNotice: '預訂須知',
    productReviews: '買家評論',
    orderForm: '訂購表格',
    selectOption: '請選擇',
    goToCart: '前往購物車',
    updateCart: '更新購物車',
    successUpdatedCart: '成功更新購物車。',
    customizedProduct: '訂製產品',
    required: '必填',

    CartItemModal: {
      productQuestions: '產品訂製資料',
      deliveryInfo: '送貨資料',
      deliveryDate: '送貨日期',
      deliveryTime: '送貨時間',
      pickupDate: '取貨日期',
      pickupTime: '取貨時間',
      contactInfo: '聯絡人資料',
      selectDistrict: '請選擇送貨地區',
      enterDeliveryAddress: '請輸入送貨地址',
      enterCustomerName: '請輸入您的名字',
      enterContactPhone: '請輸入聯絡電話',
      enterCustomerEmail: '請輸入電郵地址',
      pickup: '自取',
      delivery: '送貨上門',
      pickupPoint: '自取點',
      selectPickupPoint: '選擇自取點',
    },
    MerchantPage: {
      allProducts: '所有產品',
      numOfProducts: '商品數量',
      follow: '追蹤',
      unfollow: '取消追蹤',
      share: '分享',
    },
    ProductPage: {
      numOfCustomerReviews: '買家評論',
      newReview: '寫評論',
      successPublishProductReview: '已成功發佈商品評論。',
      reviewRating: '評分',
      reviewContent: '評論',
      publishReview: '發表評論',
      noCustomerReviews: '暫時未有買家評論',
      relatedProducts: '品牌相關推介',
      search: '搜尋商品或品牌',
    },
    HomePage: {
      seeMore: '查看更多',
      featuredProducts: '熱門推介',
      recommendedForYou: '推䔍給您',
      latestProducts: '最新上架',
      aboutUs: '關於我們',
      contactUs: '聯絡我們',
      companyAddress: '地址：',
      companyEmail: '電郵：',
      companyPhone: '公司電話：',
      home: '首頁',
      productCategories: '分類',
      specialOffers: '限時優惠',
      recentBrowse: '最近瀏覽',
      featuredMerchants: '特色品牌',
      featuredFood: '特色食品',
      latestPosts: '最新資訊',
    },
    FeedbackPage: {
      contactInfo: '聯絡資料',
      nickname: '您的名字',
      enterNickname: '請輸入您的名字',
      email: '您的電郵',
      enterEmail: '請輸入您的電郵',
      feedback: '意見反饋',
      enterFeedback: '在此輸入您的意見',
      feedbackSubmitted: '意見提交成功！',
      phone: '您的手機號碼',
      enterPhone: '請輸入您的手機號碼',
    },
    LoginPage: {
      login: '登入',
      password: '密碼',
      loginEmail: '手機號碼 / 電郵地址',
      loginWithVerificationCode: '手機驗證碼登入',
      scanLocationQRCode: '掃描地盤二維碼註冊',
      sendVerificationCode: '發送驗證碼',
    },
    RegisterPage: {
      registerAccount: '註冊帳號',
      loginEmail: '登入電郵*',
      password: '密碼*',
      confirmPassword: '確認密碼*',
      register: '註冊',
      inconsistentPasswordMsg: '兩次輸入的密碼不一致，請重新輸入。',
      enterEmailAndPassword: '請輸入電郵及密碼。',
      phone: '手機號碼',
      email: '電郵地址',
      enterPhoneAndPassword: '請輸入手機號碼、電郵及密碼。',
      enterValidHKMobileNumber: '請輸入有效的香港手機號碼 (8位數字)',
      verifyingMobileNumber: '驗證閣下的手機號碼',
      verifyMobileNumberInstruction: '請輸入短訊驗證碼，驗證碼已發送至您的手機',
      verificationCode: '短訊驗證碼',
      invalidVerificationCode: '無效短訊驗證碼！',
      verify: '驗證',
      phoneRegistered: '此手機號碼已被註冊'
    },
    UserProfilePage: {
      changeProfilePhoto: '更換頭像',
      successChangeProfilePhoto: '個人頭像更換成功！',
      editPersonalInfo: '修改個人資料',
      successUpdatePersonalInfo: '已成功更新個人資料。',
      changePassword: '更改密碼',
      oldPassword: '現有密碼',
      newPassword: '新密碼',
      newPasswordConfirm: '確認新密碼',
      inconsistentNewPasswordMsg: '兩次輸入的新密碼不一致，請重新輸入。',
      wrongOldPassword: '現有密碼輸入錯誤，請重新輸入。',
      successUpdatePassword: '成功更改密碼！',
      enterAllFields: '請輸入所有欄位。',
      myOrders: '訂單紀錄',
      myPosts: '發帖紀錄',
      likedItems: '喜愛產品',
      language: '語言',
      darkTheme: '深色主題',
      termsAndConditions: '責任及條款',
      feedback: '意見專區',
      logout: '登出',
      confirmLogout: '確定登出？',
      lastName: '姓氏',
      firstName: '名字',
      emailAddress: '電郵地址',
      phone: '手機號碼',
      save: '儲存',
      name: '姓名',
      liked: '我的喜愛清單',
      likedProducts: '喜愛產品',
      likedMerchants: '喜愛品牌',
    },
    CheckoutPage: {
      yourOrder: '您的訂單',
      orderRemark: '訂單備註',
      specialIntructions: '給賣家的特別指示',
      deliveryInfo: '送貨資料',
      selectDistrict: '請選擇送貨地區',
      enterDeliveryAddress: '請輸入送貨地址',
      enterCustomerName: '請輸入您的名字',
      enterContactPhone: '請輸入聯絡電話',
      enterCustomerEmail: '請輸入電郵地址',
      contactInfo: '聯絡資料',
      paymentInfo: '付款資料',
      confirmPayment: '確認付款',
      paymentProof: '付款證明',
      pickup: '自取',
      delivery: '送貨上門',
      pickupPoint: '自取點',
      selectPickupPoint: '選擇自取點',
      confirmOrder: '確認下單?',
      discountCode: '折扣碼',
      subTotal: '小計：',
      discountTotal: '折扣：',
      shippingTotal: '運費：',
      orderTotal: '總計：',
      invalidDiscountCode: '無效折扣碼。',
      agreeTC: '點擊「確認付款」即表示我已閱讀並接受',
      orderTC: '下單條款及細則',
    }
  },
  en: {
    done: 'Done',
    loginOrRegister: 'Login / Register',
    next: 'Next',
    loginPhone: 'Your Mobile Number',
    finish: 'Done',
    completeRegistration: 'Complete your registration',
    nickname: 'Your Nickname',
    category: 'Category',
    priceRange: 'Price Range',
    loadingMore: 'Loading...',
    brand: 'Brand',
    moq: 'Min. Order Quantity',
    minPrepDays: 'Production Time',
    orderQty: 'Order Quantity',

    '待付款': 'Pending Payment',
    '已付款': 'Paid',
    '已完成': 'Completed',

    deleteAccount: 'Delete Account',
    confirmDeleteAccountMsg: 'Confirm delete this account and all related personal information?',
    accountDeleted: 'Account deleted.',

    myPaymentMethods: 'My Payment Methods',
    saveCreditCard: 'Save your credit card',
    addNewCreditCard: 'Add a credit or debit card',
    creditCard: 'Credit Card',

    serverError: 'Oops! Something went wrong. Please try again later.',
    serverBusy: 'Oops! Server busy, please try again later.',

    shop: 'Shop',
    cart: 'Cart',
    checkout: 'Checkout',
    forum: 'Forum',
    profile: 'Profile',
    notifications: 'Notifications',
    settings:'Settings', 
    inquiry: 'Inquiry',

    sale: 'SALE',
    soldOut: 'SOLD OUT',

    yes: 'Yes',
    no: 'No',
    back: 'Back',
    confirm: 'Confirm',
    cancel: 'Cancel',
    all: 'All',

    remark: 'Remark',
    submit: 'Submit',
    
    buyNow: 'Buy Now',
    addToCart: 'Add to Cart',
    addToLikedItems: 'Add to My List',
    removeFromLikedItems: 'Remove from My List',
    enquiry: 'Enquiry',
    product: 'Product',

    successUpdateLikedItems: 'List of liked items updated successfully!',
    successAddedToCart: 'Item added to cart successfully!',

    errorHeader: 'ERROR!',

    deliveryMethod: 'Delivery Method: ',
    deliveryDistrict: 'Delivery District: ',
    deliveryAddress: 'Delivery Address: ',
    customerName: 'Customer Name: ',
    contactPhone: 'Contact Phone: ',
    customerEmail: 'Email Address: ',

    CartPage: {
      lineTotal: 'Total: ',
      orderTotal: 'Total: ',
      checkout: 'Checkout',
      shopNow: 'Shop Now',
      emptyCart: 'Your shopping cart is empty',
      removeCartItem: 'Remove Cart Item',
      confirmRemoveCartItem: 'Confirm removing this item from cart?',
    },

    OrderPage: {
      order: 'Order',
      orderStatus: 'Order Status',
      orderItems: 'Order Items',
      lineTotal: 'Line Total: ',
      orderTotal: 'Order Total: ',
      deliveryInfo: 'Delivery Information',
      orderNumber: 'Order ID: ',
      total: 'Total: ',
    },

    PostPage: {
      newPost: 'New Post',
      lastUpdated: 'Last Updated: ',
      numOfComments: ' Comments',
      newComment: 'New Comment',
      commentBtnText: 'COMMENT',
      editPost: 'Edit Post',
      postTitle: 'Post Title',
      postContent: 'Post Content',
      postImage: 'Image',
      updatePost: 'Update Post',
      publishPost: 'Publish Post',
      successPublishPost: 'Post published successfully!',
      successUpdatePost: 'Post updated successfully!',
      confirmDelete: 'Confirm Delete',
      confirmDeletePost: 'Confirm delete this post?',
      deletePost: 'Delete Post',
      postDeleted: 'Post deleted.',
    },

    ThankYouPage: {
      thankYouForYourOrder: 'Thank you for your order!',
      description: "You will soon receive an order confirmation notification",
      backToHomePage: 'Back to Home',
    },

    // add extra en translations below
    registeringAccount: 'Setting up account...',
    loginInformation: 'Login Account Information',
    successUpdatePersonalInfo: 'Personal information updated successfully.',
    clashEmailAddress: 'The email address is already in use by another account.',
    wrongPassword: 'Login information is incorrect.',
    editMobileNumber: 'Edit Mobile Number',

    home: 'Home',
    merchant: 'Sellers',
    allMerchants: 'All Sellers',
    latestPosts: 'Latest News',
    readMore: 'Read More',
    search: 'Search',

    shareProduct: 'Share',
    earliestDeliveryDate: 'Earlist Delivery Date',
    pickupPoints: 'Pick-up Points',
    deliveryDistricts: 'Delivering Districts',
    hki: 'HKI',
    kln: 'KLN',
    nt: 'NT',

    '港島': 'HKI',
    '九龍': 'KLN',
    '新界': 'NT',
    '離島': 'ISL',
    shareWithFriends: 'Share',
    productIntro: 'Introduction',
    orderNotice: 'Order Notice',
    productReviews: 'Product Reviews',
    orderForm: 'Order Form',
    selectOption: 'Please select',
    goToCart: 'Go to Cart',
    updateCart: 'Update Cart',
    successUpdatedCart: 'Cart updated successfully.',
    customizedProduct: 'Customized',
    required: 'Required',

    CartItemModal: {
      productQuestions: 'Product Customization',
      deliveryInfo: 'Delivery Information',
      deliveryDate: 'Delivery Date',
      deliveryTime: 'Delivery Time',
      pickupDate: 'Pick-up Date',
      pickupTime: 'Pick-up Time',
      contactInfo: 'Contact Information',
      selectDistrict: 'Select the delivery district',
      enterDeliveryAddress: 'Enter the delivery address',
      enterCustomerName: 'Enter your name',
      enterContactPhone: 'Enter the contact phone',
      enterCustomerEmail: 'Enter the email address',
      pickup: 'Pick up',
      delivery: 'Delivery',
      pickupPoint: 'Pick-up Point',
      selectPickupPoint: 'Select pick-up point',
    },
    MerchantPage: {
      allProducts: 'All',
      numOfProducts: 'Total Products',
      follow: 'Follow',
      unfollow: 'Unfollow',
      share: 'Share',
    },
    ProductPage: {
      numOfCustomerReviews: ' Customer Reviews',
      newReview: 'Write a review',
      successPublishProductReview: 'Review published successfully!',
      reviewRating: 'Rating',
      reviewContent: 'Review',
      publishReview: 'Publish Review',
      noCustomerReviews: 'No Customer Reviews',
      relatedProducts: 'Related Products',
      search: 'Search products or brands',
    },
    HomePage: {
      seeMore: 'See More',
      home: 'Home',
      productCategories: 'Categories',
      specialOffers: 'Special Offers',
      recentBrowse: 'Recently Watched',
      featuredMerchants: 'Featured Brands',
      featuredFood: 'Featured Food',
      latestPosts: 'Latest News',
      featuredProducts: 'Featured Products',
      recommendedForYou: 'Recommended For You',
      latestProducts: 'Latest Products',
    },
    FeedbackPage: {
      contactInfo: 'Contact Information',
      nickname: 'Your name',
      enterNickname: 'Enter your name',
      email: 'Your email',
      enterEmail: 'Enter your email',
      feedback: 'Feedback',
      enterFeedback: 'Enter your feedback here',
      feedbackSubmitted: 'Feedback submitted successfully!',
      phone: 'Your phone',
      enterPhone: 'Enter your phone',
    },
    LoginPage: {
      login: 'Login',
      password: 'Password',
      loginEmail: 'Mobile Number / Email',
      loginWithVerificationCode: 'Log in with SMS Verification Code',
      scanLocationQRCode: 'Register with QR Code',
      sendVerificationCode: 'Send Verification Code',
    },
    RegisterPage: {
      registerAccount: 'Register',
      loginEmail: 'Login Email*',
      password: 'Password*',
      confirmPassword: 'Confirm Password*',
      register: 'Register',
      enterEmailAndPassword: 'Please enter both the email address and password.',
      inconsistentPasswordMsg: 'Passwords do not match. Please enter again.',
      phone: 'Mobile Number',
      email: 'Email',
      enterPhoneAndPassword: 'Please enter your mobile number, email and password.',
      enterValidHKMobileNumber: 'Please enter a valid Hong Kong mobile number (8 digits)',
      verifyingMobileNumber: 'Verify your mobile number',
      verifyMobileNumberInstruction: 'Please enter the verification code that was sent to your mobile device',
      verificationCode: 'Verification Code',
      invalidVerificationCode: 'Invalid verification code!',
      verify: 'Verify',
      phoneRegistered: 'This phone has already been registered'
    },
    UserProfilePage: {
      changeProfilePhoto: 'Change Profile Photo',
      successChangeProfilePhoto: 'Profile photo changed successfully!',
      editPersonalInfo: 'Edit Personal Information',
      successUpdatePersonalInfo: 'Personal information updated successfully!',
      changePassword: 'Change Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      newPasswordConfirm: 'Confirm New Password',
      inconsistentNewPasswordMsg: 'New passwords do not match. Please enter again.',
      wrongOldPassword: 'The old password is wrong. Please enter again.',
      successUpdatePassword: 'Password updated successfully!',
      enterAllFields: 'Please enter all fields.',
      myOrders: 'My Orders',
      myPosts: 'My Posts',
      likedItems: 'Liked Items',
      language: 'Language',
      darkTheme: 'Dark Theme',
      termsAndConditions: 'Terms and Conditions',
      feedback: 'Feedback',
      logout: 'Log Out',
      confirmLogout: 'Confirm log out?',
      lastName: 'Last Name',
      firstName: 'First Name',
      emailAddress: 'Email Address',
      phone: 'Phone',
      save: 'Save',
      name: 'Name',
      liked: 'My List',
      likedProducts: 'Liked Products',
      likedMerchants: 'Liked Brands',
    },
    CheckoutPage: {
      yourOrder: 'Your Order',
      orderRemark: 'Order Remark',
      specialIntructions: 'Special Intructions to the Seller',
      deliveryInfo: 'Delivery Information',
      selectDistrict: 'Select the delivery district',
      enterDeliveryAddress: 'Enter the delivery address',
      enterCustomerName: 'Enter your name',
      enterContactPhone: 'Enter the contact phone',
      enterCustomerEmail: 'Enter the email address',
      contactInfo: 'Contact Information',
      paymentInfo: 'Payment Information',
      confirmPayment: 'Submit',
      paymentProof: 'Payment Proof',
      pickup: 'Pick up',
      delivery: 'Delivery',
      pickupPoint: 'Pick-up Point',
      selectPickupPoint: 'Select pick-up point',
      confirmOrder: 'Confirm Placing Order?',
      discountCode: 'Discount Code',
      subTotal: 'Subtotal: ',
      discountTotal: 'Discount: ',
      shippingTotal: 'Shipping Total: ',
      orderTotal: 'Grand Total: ',
      invalidDiscountCode: 'Invalid Discount Code!',
      agreeTC: 'By clicking "Submit" I agree',
      orderTC: 'the Order Terms & Conditions',
    }
  }
}