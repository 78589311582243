import moment from 'moment';

const formatDate = (date, dateFormat = 'YYYY/MM/DD HH:mm') => moment(new Date(date)).format(dateFormat);
const addDaysToDate = (date, days) => {
  days = days == "" ? 1 : days; // default add 1 day
  return (moment(new Date(date))).add(days, 'days');
}

// ORDER
export const getOrderById = (state) => (id) => {
  return state.userOrders.find(o => o.id == id) || {};
}

// CART ITEMS
export const cartTotal = (state) => (state.cartItems.reduce((a, b) => +a + (b.unitPrice * b.quantity), 0));
export const numOfCartItems = (state) => (state.cartItems.length);
export const getCartItem = (state) => (productId) => (state.cartItems.find(item => item.productId == productId));
export const getGroupedCartItems = (state, getters) => {
  const groupedObj = {}; // group by merchants
  for (const item of state.cartItems) {
    const { productId, answers, unitPrice, quantity, } = item;
    const merchant = state.allMerchants.find(m => m.relatedProducts.includes(productId));
    if (merchant) {
      const product = getters.getProductById(productId);
      const productQuestions = getters.getQuestionsByProductId(productId);
      const productPrepDays = getters.getPrepDaysByProductId(productId);

      // Calculate product delivery date
      let minPrepDays = product.minPrepDays || 7; // hard-coded default (should not happen)
      if (productPrepDays.length > 0) {
        for (const ppd of productPrepDays) {
          const fromQty = parseInt(ppd.fromQty || 0), toQty = ppd.toQty !== '' ? parseInt(ppd.toQty) : Infinity;
          if (quantity >= fromQty && quantity <= toQty) {
            minPrepDays = ppd.minPrepDays; // found matched rule
            break;
          }
        }
      }
      const minDeliveryDate = formatDate(addDaysToDate(new Date(), minPrepDays), "YYYY-MM-DD");

      if (!(merchant.id in groupedObj)) {
        const pickupPoints = getters.getPickupPointsByMerchantId(merchant.id);
        const groupedDeliveryOptions = getters.getGroupedDeliveryOptionsByMerchantId(merchant.id);
        merchant.deliveryMethod = "送貨上門"; // default delivery method
        merchant.deliveryDate = minDeliveryDate; // default delivery date
        merchant.deliveryAddress = Object.keys(groupedDeliveryOptions).length > 0 ? '' : (state.user.deliveryAddress || 'testing address');
        groupedObj[merchant.id] = {
          merchant, pickupPoints, groupedDeliveryOptions,
          cartItems: [], totalPrice: 0, minDeliveryDate,
        };
      }
      if (answers) { // server cart items
        const questionAnswers = {}, checkedCbxQuestions = [];
        for (const obj of answers) {
          const { questionId, optionIds, answer } = obj;
          const relatedQuestion = productQuestions.find(q => q.id == questionId);
          if (relatedQuestion) {
            if (optionIds) { // select / multi-select
              const selectedOptions = optionIds.split(" , ").map(optionId => relatedQuestion.options.find(opt => opt.id == optionId));
              questionAnswers[questionId] = relatedQuestion.type == 'single-select' ? selectedOptions[0] : selectedOptions;
            } else {
              if (relatedQuestion.type == 'quantity-input') {
                questionAnswers[questionId] = answer; // quantity input question
              } else {
                relatedQuestion.textboxAns = answer; // textarea
                checkedCbxQuestions.push(relatedQuestion); // checkboxes
              }
            }
          }
        }
        item.questionAnswers = questionAnswers;
        item.checkedCbxQuestions = checkedCbxQuestions;
      }
      item.productQuestions = productQuestions;
      groupedObj[merchant.id].cartItems.push(item);
      groupedObj[merchant.id].totalPrice += Number(unitPrice * quantity);
      if (minDeliveryDate > groupedObj[merchant.id].minDeliveryDate) {
        groupedObj[merchant.id].minDeliveryDate = minDeliveryDate; // use the large min delivery date
        groupedObj[merchant.id].merchant.deliveryDate = minDeliveryDate; // update the selected delivery date
      }
    }
  }
  return groupedObj;
}

// DISTRICT
export const getDistrictById = (state) => (id) => (state.allDistricts.find(d => d.id == id));

// PICKUP POINT
export const getPickupPointsByMerchantId = (state) => (merchantId) => {
  return state.pickupPoints.filter(p => p.merchantId == merchantId);
}

// DELIVERY OPTION
export const getGroupedDeliveryOptionsByMerchantId = (state, getters) => (merchantId) => {
  const groupedOptions = {}; // group by region then by group then by price
  const options = state.deliveryOptions.filter(o => o.merchantId == merchantId);
  for (const option of options) {
    const { districts, price } = option;
    const districtIds = districts.split(" , ");
    for (const districtId of districtIds) {
      const district = getters.getDistrictById(districtId);
      if (district) {
        const { region, group } = district;
        if (region && group) {
          groupedOptions[region] = groupedOptions[region] || {};
          groupedOptions[region][group] = groupedOptions[region][group] || {};
          (groupedOptions[region][group][price] = groupedOptions[region][group][price] || []).push(district);
        }
      }
    }
  }
  return groupedOptions;
}

// MERCHANT
export const getMerchantById = (state) => (id) => {
  return state.allMerchants.find(m => m.id == id) || {};
}
export const featuredMerchants = (state) => {
  return state.allMerchants.filter(m => m.isFeatured == "Y");
}
export const latestMerchants = (state) => {
  return state.allMerchants.slice(-5);
}
export const merchantsWithProductCategory = (state) => (category = 'all') => {
  const tmpObj = {};
  for (const product of state.allProducts) {
    const { merchantId, merchantName, categoryId } = product;
    if (merchantId && (category == 'all' || categoryId == category)) {
      if (!(merchantId in tmpObj)) tmpObj[merchantId] = { id: merchantId, name: merchantName, numOfProducts: 0};
      tmpObj[merchantId].numOfProducts++;
    }
  }
  return Object.values(tmpObj);
}
export const userLikedMerchants = (state) => {
  return state.allMerchants.filter(m => m.likedByUser);
}

// PRODUCT & PRODUCT CATEGORY
export const latestProducts = (state) => {
  return state.allProducts.slice(0, 10); // return latest 10 products (reversed in server-side)
}
export const getPrepDaysByProductId = (state) => (productId) => {
  return state.productPrepDays.filter(ppd => ppd.productId == productId && ppd.minPrepDays).sort((a, b) => {
    return a.minPrepDays < b.minPrepDays ? -1 : 1;
  });
}
export const getQuestionsByProductId = (state) => (productId) => {
  return state.productQuestions.filter(q => q.productId == productId);
}
export const getProductsByMerchantId = (state, getters) => (merchantId, excludeProductId) => {
  const productCategories  = {};
  const products = [];
  for (const p of state.allProducts) {
    if (p.merchantId == merchantId) {
      if (excludeProductId && p.id == excludeProductId) continue;
      products.push(p);
      if (p.categoryId) {
        if (!(p.categoryId in productCategories)) {
          const c = getters.getProductCategoryById(p.categoryId);
          productCategories[p.categoryId] = { id: c.id, title: c.title, numOfProducts: 1 };
        } else {
          productCategories[p.categoryId].numOfProducts++;
        }
      }
    }
  }
  return {
    products, categories: Object.values(productCategories),
  }
}
export const getProductCategoryById = (state) => (categoryId) => (state.allProductCategories.find(c => c.id == categoryId));
export const userRecentBrowsedProducts = (state) => {
  const { browsedProductIds } = state.user;
  return browsedProductIds ? state.allProducts.filter(p => (browsedProductIds.includes(p.id))) : [];
}

// BLOG
export const latestPosts = (state) => (state.allPosts.slice(-5));

// HOME SECTIONS
export const getHomeSectionById = (state) => (sectionId) => {
  return state.homeSections.find(s => s.id == sectionId);
}
export const getProductsBySectionId = (state) => (sectionId) => {
  const products = [];
  for (const p of state.allProducts) {
    if (p.relatedHomeSectionProducts.includes(sectionId)) products.push(p);
  }
  return products;
}

/**
 * Getters for Products
 */
export const getProductById = (state) => (id) => {
  // mainly used in Product Details Page
 return state.allProducts.find(product => product.id == id) || {};
}
export const featuredProducts = (state) => {
  return state.allProducts.filter(product => product.isFeatured == "Y");
}
export const userLikedItems = (state, getters) => {
  return state.allProducts.filter(product => product.likedByUser);
}
export const allProductTags = (state) => (category = 'all') => {
  const tmpObj = {};
  for (const product of state.allProducts) {
    if (product.tags && (category == 'all' || product.categoryId == category)) {
      for (const tag of product.tags.split(" , ")) {
        tmpObj[tag] = (tmpObj[tag] || 0) + 1;
      }
    }
  }
  return Object.keys(tmpObj).map(tag => {
    return { name: tag, numOfProducts: tmpObj[tag] };
  });
}

/**
 * Getters for Posts
 */
export const getPostById = (state) => (id) => {
  // mainly used in Product Details Page
 return state.allPosts.find(post => post.id == id) || {};
}


/**
 * Getters for Notification
 */
export const getNotificationById = (state) => (id) => {
  return state.allNotifications.find(n => n.id == id) || {};
}
export const numOfNewNotifications = (state, getters) => {
  return state.allNotifications.filter(notification => {
    return state.user.lastCheckNotificationsAt ? 
          new Date(notification.scheduledToSendAt) > new Date(state.user.lastCheckNotificationsAt) : true;
  }).length;
}