import { createApp } from 'vue'
import AppComponent from '@/App.vue'
import { routes } from '@/router';
import { createRouter, createWebHistory } from '@ionic/vue-router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.css';
import '@/theme/global.css';

/* Firebase */
import { auth } from '@/auth';

/* vue-i18n */
import { createI18n } from 'vue-i18n';
import translationMessages from '@/i18n';
import config from '@/config';

/* Vuex */
import store from '@/store'

import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Capacitor */
import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

import UserService from '@/services/UserService';
import CommonService from '@/services/CommonService';
import CartService from '@/services/CartService';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

document.documentElement.style.setProperty("--ion-color-primary", config.primaryColor);

const i18n = createI18n({
  locale: 'zh', // set locale
  messages: translationMessages,
  silentTranslationWarn: true,
  legacy: false,
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const app = createApp(AppComponent)
  .use(store)
  .use(i18n)
  .use(IonicVue, { mode: 'md' })
  .use(router)

// Global components (for nested import)
import PageHeader from '@/components/PageHeader.vue';
app.component('PageHeader', PageHeader);

router.isReady().then(() => {
  /* Retrieve data from AppSheet and save it in Vuex store as state variables */
  CommonService.getPublicData().then(res => {
    store.commit('receiveAppPublicData', res);
    SplashScreen.hide();
  });

  auth.onAuthStateChanged((user: any) => {
    if (user) { // logged in
      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId(user.uid); // Map OneSignal player ID to Firebase User ID
      }
      const redirectPath: any = router.currentRoute.value.query.redirectPath;
      if (redirectPath) {
        router.replace(redirectPath); // redirect to specific page after login
      }
      else if (config.authPages.includes(router.currentRoute.value.path)) {
        router.replace('/home'); // go to home page if the user in auth pages
      }
      store.dispatch('getUserData', { firebaseUser: user, i18n });

      // notifications
      CommonService.getUserNotifications().then(res => {
        store.commit('receiveNotifications', res);
      })
    } else { // logged out
      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId("");
      }
      store.dispatch('resetUserData');
      i18n.global.locale.value = navigator.language.split('-')[0].toLowerCase() == "en" ? "en" : "zh";
      const currentPath = router.currentRoute.value.path;
      if (config.memberPages.includes(currentPath)) {
        router.replace({ path: '/login', query: { redirectPath: currentPath } });
      }
    }
  });
  app.mount('#app');

  /* Check App state & events (e.g. enter App foreground & refresh data) */
  App.addListener('appStateChange', ({ isActive }) => {
    if (isActive) {
      CommonService.getPublicData().then(res => {
        store.commit('receiveAppPublicData', res);
      });
    }
  });

  /* Check App Deeplink & do redirect */
  App.addListener('appUrlOpen', (data) => {
    const slug = data.url.split(/\.app|\.com/).pop();
    if (slug) {
      router.replace(slug);
    }
  });

  /**
   * OneSignal
   **/
  window["plugins"].OneSignal.setAppId(config.OneSignal.appId);
  window["plugins"].OneSignal.setNotificationOpenedHandler((jsonData) => {
    // triggerred on tap notifications from devices
    const { additionalData } = (jsonData.notification.payload || {});
    if (additionalData) {
      const { app_external_link: externalLink, app_notification_id: notificationId,
              app_product_id: productId, app_merchant_id: merchantId } = additionalData;
      if (productId) {
        router.replace(`/products/${productId}`);
      }
      else if (merchantId) {
        router.replace(`/merchants/${merchantId}`);
      }
      else if (externalLink) {
        Browser.open({ url: externalLink, toolbarColor: config.primaryColor });
        ////router.replace({ name: 'InfoOverviewPage', state: { targetInfoLink: externalLink } }); // go to information list page and open specific info
      }
      else if (notificationId) {
        CommonService.getUserNotifications().then(res => {
          store.commit('receiveNotifications', res); // refresh list of notifications
        })
        router.replace(`/notifications/${notificationId}`);
      }
    }
  });

  // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
  window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse((accepted: any) => {
    console.log("User accepted notifications: " + accepted);
  });
});

router.beforeEach((to, from) => {
  if (auth.currentUser == null) { // guests
    if (config.memberPages.includes(to.path)) {
      //const redirectPath = to.path == '/checkout' ? '/cart' : to.path;
      //return { path: '/login', query: { redirectPath } }; // quite likely to cause bugs
      return { path: '/login' };
    }
  } else { // logged in users
    if (to.path == '/notifications') {
      // update user last click notification tab time
      store.dispatch('updateUserLastCheckNotificationTime');
    }
  }
})